<template>
	<div>
		<div class="text-center" v-if="!isPastDue && !isPro && routeName != 'pro'">
			<div class="h3 mb-2">You're on the free plan.</div>
			<div class="mb-4 text-muted">Upgrate to Amino Pro for unlimited storage and an ad-free experience.</div>
			<b-button to="/pro" variant="primary">Get Amino Pro</b-button>
		</div>
		<div v-else-if="isPastDue" class="text-center">
			<div class="h3 mb-2">
				Your account is past due. There was an issue processing your payment method. 
			</div>
			<div v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" class="my-5">
				<div class="mb-2">
					We were unable to bill the <span class="text-uppercase">{{ paymentMethod.card.brand }}</span> card ending in {{ paymentMethod.card.last4 }}. Please update your payment method below.
				</div>
			</div>
			<div v-if="clientSecret">
				<div class="stripe-wrapper border rounded p-3">
					<card :options="stripeOptions" class="stripe-card" ref="card" :class="{ complete }" :stripe="stripeKey" @change="complete = $event.complete" />
				</div>
				<div class="mt-5">
					<b-button @click.prevent="updateCard(true)" variant="primary">Update &amp; Process Payment</b-button>
				</div>
			</div>
		</div>
		<div class="text-left" v-else-if="!isPro">
			<div v-if="clientSecret" title="Enter a payment method" class="mb-2">
				<div class="h1 m-0">Upgrade to Amino Pro</div>
				<div class="mb-4" v-if="!loading">
					<div v-for="plan in filteredPlans" v-bind:key="plan.id">
						<template v-if="plan.interval == 'year' && activeCoupon">
							<div class="d-flex align-items-center my-3">
								<div class="h4">
									<del>${{ plan.amount / 100 }}/{{ plan.interval }}</del> ${{ activeCoupon.price }}/year
								</div>
								<div v-if="activeCoupon.badge" class="d-flex align-items-center ml-3">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 24px;" class="mr-2">
										<path fill="#7F78D2" fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
									</svg>
									<div class="font-weight-bold small">
										{{ activeCoupon.badge }}
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							<span class="text-muted">
								${{ plan.amount / 100 }}/{{ plan.interval }}
							</span>
						</template>
					</div>
				</div>
				<div class="font-weight-bold mb-3" v-if="!loading">Enter a payment method</div>
				<div class="text-muted mb-3" v-else>Setting things up on your account</div>
				<div class="stripe-wrapper border rounded p-3">
					<card :options="stripeOptions" class="stripe-card" ref="card" :class="{ complete }" :stripe="stripeKey" @change="complete = $event.complete" />
				</div>
				<!-- <b-button href="#" variant="primary" class="pay-with-stripe" @click.prevent="saveCard" :disabled="!complete">Add Card</b-button> -->
			</div>
			<div class="mt-4">
				<b-overlay
					:show="loading"
					opacity="0.6"
					spinner-small
					spinner-variant="dark"
					class="d-inline-block"
				>
					<b-button v-for="plan in filteredPlans" v-bind:key="plan.id" @click="subscribeNow(plan)" variant="primary">
						Upgrade plan
					</b-button>
				</b-overlay>
			</div>
			<div class="d-flex align-items-center mt-4">
				<div class="small text-muted mr-2">
					Powered by
				</div>
				<div>
					<svg height="16"><path fill="white" d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></svg>
				</div>
			</div>
		</div>
		<div v-else-if="routeName === 'pro'">
			<div class="alert">
				<b-container>
					<b-row>
						<b-col>
							<div class="h4">Your Pro subscription is active.</div>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-button variant="primary" to="/account/plans">Manage Your Subscription</b-button>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
		<div v-else-if="user.subscription.cancel_at > 0">
			<div class="h3 mb-4 text-center">Your Pro subscription has been canceled</div>
			<div class="mb-3">
				<span class="material-icons-outlined text-primary mr-2">done</span>
				You will be downgraded to the free plan at the end of your current billing cycle on {{ user.subscription.cancel_at | moment('MM / DD / YYYY')  }}
			</div>
			<div class="mb-4">
				<span class="material-icons-outlined text-primary mr-2">done</span>
				You can easily restart your subscription anytime
			</div>
			<b-overlay
				:show="loading"
				opacity="0.6"
				spinner-small
				spinner-variant="dark"
				class="d-inline-block"
			>
				<b-button variant="primary" @click.prevent="continueSubscription(user.subscription.id)">Restart Subscription</b-button>
			</b-overlay>
		</div>
		<div v-else>
			<div class="h5 mb-2">Your subscription will auto-renew on: {{ user.subscription.current_period_end | moment('MM/DD/YYYY') }}</div>
			<div class="text-muted mb-4">Renewal Rate: ${{ user.subscription.plan.amount / 100 }} ({{ user.subscription.plan.nickname }})</div>
			<div class="stripe-wrapper border rounded p-3" v-if="editCard">
				<card :options="stripeOptions" class="stripe-card" ref="card" :class="{ complete }" :stripe="stripeKey" @change="complete = $event.complete" />
			</div>
			<!-- <div v-else v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" style="width: 400px; height: 275px; background-size: contain; background-repeat: no-repeat; margin: 0 auto;" :style="{ backgroundImage: 'url(' + require('@/assets/images/cc-bg.png') + ')' }">
				<div style="height: 165px; padding: 32px; font-weight: bold; text-transform: uppercase;">
					{{ paymentMethod.card.brand }}
				</div>
				<table style="margin: 0px 30px 0px 30px;">
					<tr><td colspan="2" style="word-spacing: 35px; font-weight: bold;">○○○○ ○○○○ ○○○○ {{ paymentMethod.card.last4  }}</td></tr>
					<tr><td></td><td align="right" style="padding-top: 20px; font-weight: bold; color: #888ea3;">{{ paymentMethod.card.exp_month }} / {{ paymentMethod.card.exp_year }}</td></tr>
				</table>
			</div> -->
			<div v-else v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" class="my-5">
				<div class="h5">How you pay</div>
				<div class="mb-2">
					<span class="text-uppercase">{{ paymentMethod.card.brand }}</span> &#8226;&#8226;&#8226;&#8226; {{ paymentMethod.card.last4 }}
				</div>
				<div class="small text-muted">{{ paymentMethod.card.exp_month }} / {{ paymentMethod.card.exp_year }}</div>
			</div>
			<b-list-group>
				<b-overlay
					:show="loading"
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
				>
					<b-list-group-item v-if="!editCard">
						<b-link class="font-weight-bold" @click="editCard = true">Update your payment method</b-link>
					</b-list-group-item>
					<b-list-group-item v-if="!editCard">
						<b-link class="font-weight-bold" v-if="user.subscription.plan.nickname != 'Monthly'"  v-b-modal.modal-approve-plan-change>
							Switch to monthly payments - $3.99/mo
						</b-link>
						<b-link class="font-weight-bold" v-if="user.subscription.plan.nickname != 'Annual'" v-b-modal.modal-approve-plan-change>
							Switch to annual payments - $35.88/year (30% savings)
						</b-link>
					</b-list-group-item>
					<b-list-group-item v-else>
						<b-button @click="updateCard" variant="primary">Save</b-button>
						<b-button @click="editCard = false" variant="text" class="text-white small">Cancel</b-button>
					</b-list-group-item>
					<b-list-group-item v-if="!editCard">
						<b-link class="font-weight-bold" v-b-modal.modal-cancel-survey>Cancel your subscription</b-link>
					</b-list-group-item>
				</b-overlay>
			</b-list-group>
		</div>
		<div class="alert alert-danger mt-5" v-if="error">{{ error }}</div>
		<b-modal hide-header centered class="text-center" id="modal-approve-plan-change">
			<div class="h5 mb-3">Are you sure you want to change your plan?</div>
			<p class="text-muted" v-if="user.subscription.plan.nickname != 'Annual'">We will charge $35.88 to the card on file.</p>
			<template v-slot:modal-footer>
				<b-row>
					<b-col cols="auto" class="pr-0 pl-0 align-self-center">
						<b-button variant="link" @click.prevent="$bvModal.hide('modal-approve-plan-change')">Cancel</b-button>
					</b-col>
					<b-col cols="auto" class="pl-0 pr-2">
						<b-overlay
							:show="loading"
							opacity="0.6"
							spinner-small
							spinner-variant="dark"
							class="d-inline-block"
						>
							<b-button v-if="user.subscription.plan.nickname != 'Monthly'" variant="primary" @click.prevent="changeSubscription('Monthly')">Downgrade Now</b-button>
							<b-button v-if="user.subscription.plan.nickname != 'Annual'" variant="primary" @click.prevent="changeSubscription('Annual')">Upgrade Now</b-button>
						</b-overlay>
					</b-col>
				</b-row>
			</template>
		</b-modal>
		<b-modal hide-header centered class="text-center" id="modal-cancel-survey">
			<div class="h5 mb-3">Why do you want to cancel?</div>
			<b-form-group class="text-left mb-4">
				<b-form-radio v-model="cancelReason" name="cancel-reason" value="Lacking premium features">Lacking premium features</b-form-radio>
				<b-form-radio v-model="cancelReason" name="cancel-reason" value="Too expensive">Too expensive</b-form-radio>
				<b-form-radio v-model="cancelReason" name="cancel-reason" value="Didn't use it enough">Didn't use it enough</b-form-radio>
				<b-form-radio v-model="cancelReason" name="cancel-reason" value="Temporary, I'll be back">Temporary, I'll be back</b-form-radio>
				<b-form-radio v-model="cancelReason" name="cancel-reason" value="Technical issues">Technical issues</b-form-radio>
			</b-form-group>
			<textarea class="w-100 bg-dark text-white p-2"
				id="textarea"
				placeholder="Any other feedback you want to share with us?"
				rows="3"
				max-rows="6"
				v-model="cancelFeedback"
			></textarea>
			<template v-slot:modal-footer>
				<b-button variant="primary" @click="$bvModal.hide('modal-cancel-survey');" v-b-modal.modal-cancel-confirmation>Continue to Cancel</b-button>
			</template>
		</b-modal>
		<b-modal hide-header centered class="text-center" id="modal-cancel-confirmation">
			<div class="h5 mb-3">You will be downgraded to the free plan.</div>
			<div class="text-muted">If you have exceeded the storage limit of the free tier, you will be unable to continue to add stylesheets. However, you will continue to have access to all your existing CSS.</div>
			<template v-slot:modal-footer>
				<b-row>
					<b-col cols="auto" class="pl-0 pr-2">
						<b-button variant="primary" @click="$bvModal.hide('modal-cancel-confirmation')">Nevermind</b-button>
					</b-col>
					<b-col cols="auto" class="pr-0 pl-0 align-self-center">
						<b-overlay
							:show="loading"
							opacity="0.6"
							spinner-small
							spinner-variant="dark"
							class="d-inline-block"
						>
							<b-button variant="link" @click="cancelSubscription(user.subscription.id);">Cancel Now</b-button>
						</b-overlay>
					</b-col>
				</b-row>
			</template>
		</b-modal>
		<b-modal hide-header centered class="text-center" id="modal-delete-account">
			<div class="h3 mb-3">We're sorry to see you go.</div>
			<div class="text-muted">Be advised, account deletion is final. Your data will be deleted from the system, and there will be no way to restore your account.</div>
			<template v-slot:modal-footer>
				<b-row>
					<b-col cols="auto" class="align-self-center pl-0 mr-2">
						<b-button variant="primary" v-b-modal.modal-delete-account-confirm @click="$bvModal.hide('modal-delete-account');">Delete my account</b-button>
					</b-col>
					<b-col cols="auto" class="align-self-center pr-0 pl-0">
						<b-button variant="link" class="p-0" @click="$bvModal.hide('modal-delete-account')">Cancel</b-button>
					</b-col>
				</b-row>
			</template>
			<div class="alert alert-primary small mt-4 mb-0" v-if="isPro">Your active subscription will be canceled immediately. We do not provide refunds or prorations.</div>
		</b-modal>
		<b-modal hide-header centered class="text-center" id="modal-delete-account-confirm">
			<div class="h3 mb-2">Are you sure you want to permanently delete your account and data?</div>
			<div class="text-muted">You cannot undo this action.</div>
			<template v-slot:modal-footer>
				<b-row>
					<b-col cols="auto" class="align-self-center pl-0 mr-2">
						<b-button variant="primary" @click="deleteAccount">I understand, delete my account</b-button>
					</b-col>
					<b-col cols="auto" class="align-self-center pr-0 pl-0">
						<b-button variant="link" class="p-0" @click="$bvModal.hide('modal-delete-account-confirm')">Cancel</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>
		<b-modal hide-header hide-footer centered class="text-center" id="modal-delete-account-confirmation">
			<div class="h3 mb-2">Your account has been deleted.</div>
			<div class="text-muted">You are now being logged out. Go in peace!</div>
		</b-modal>
	</div>
</template>

<script>
	import { makeFindMixin } from 'feathers-vuex';
	const logo = require('../assets/images/icon.svg');
	import { mapActions } from 'vuex'
	import { Card, handleCardSetup } from 'vue-stripe-elements-plus';
	export default {
		name: 'PaymentsForm',
		mixins: [ makeFindMixin({ service: 'coupons' }) ],
		components: {
			Card
		},
		data() {
			return {
				complete: false,
				clientSecret: false,
				cancelReason: "",
				cancelFeedback: "",
				editCard: false,
				paymentMethods: [],
				plans: [],
				subscription: { status: false, plan: {} },
				loading: false,
				error: false,
				stripeOptions: {
					style: {
						base: {
							color: 'white',
							fontFamily: 'Graphik, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
							fontSmoothing: 'antialiased',
							fontWeight: '500',
							fontSize: '16px',
							'::placeholder': {
								color: '#BDBDBD',
							},
						},
						invalid: {
							color: '#fa6657'
						}
					}
				},
				logo
			};
		},
		props: [
			'nickname'
		],
		methods: {
			...mapActions('subscription', {
				updateSubscription: 'update'
			}),
			...mapActions('invoice', {
				updateInvoice: 'patch'
			}),
			async subscribeNow (plan) {
				this.loading = true;
				this.error = false;
				try {
					await this.saveCard();
					await this.loadPaymentMethods();
					this.createSubscription(plan.id).then(async subscription => {
						if (subscription.status === 'incomplete') {
							await this.subscription.remove();
							await this.resetSubscriptionToUser();
							this.error = 'There was an error processing your payment. Please try again...';
							this.loading = false;
							this.$refs.card.clear();
							this.createSetupIntent();
						} else {
							await this.saveSubscriptionToUser();
							this.$router.push('/library');
						}
					});
				} catch(e) {
					this.error = e.message;
					this.loading = false;
				}
			},
			saveCard() {
				this.complete = false;
				return handleCardSetup(this.clientSecret).then(async () => {
					if (this.paymentMethods.length) {
						await this.deletePaymentMethod(this.paymentMethods[0].id);
					}
				});
			},
			updateCard (payInvoice) {
				this.loading = true;
				return handleCardSetup(this.clientSecret).then(async () => {
					this.editCard = false;
					await this.loadPaymentMethods();
					if (this.paymentMethods.length > 1) {
						await this.deletePaymentMethod(this.paymentMethods[1].id);
					}
					this.user.paymentMethod = this.paymentMethods[0].id;
					await this.user.save();
					if (payInvoice) {
						const invoice = await this.Invoice.get(this.subscription.latest_invoice);
						invoice.pay = true;
						await invoice.patch().then(console.log).catch(console.log);
						await this.loadSubscription();
						this.saveSubscriptionToUser();
					}
					this.loading = false;
					this.makeToast('Payment method updated');
					this.createSetupIntent();
				}).catch(err => {
					this.loading = false;
					console.log(err);
					this.error = 'There was an error adding your card. Please try again...';
					this.$refs.card.clear();
					this.createSetupIntent();
					this.editCard = true;
				});
			},
			loadPaymentMethods() {
				return this.PaymentMethod.find({
					query: {
						customer: this.user.customerId,
						type: 'card'
					}
				}).then(res => {
					return this.paymentMethods = res.data;
				});
			},
			loadPlans() {
				this.Plan.find().then(res => {
					this.plans = res.data;
				});
			},
			deletePaymentMethod(id) {
				return this.PaymentMethod.get(id).then(paymentMethod => {
					paymentMethod.remove().then(() => {
						this.loadPaymentMethods();
					});
				}).catch(() => {
					this.makeToast('There was an removing your payment method');
				});
			},
			createSubscription(plan) {
				const interval = this.filteredPlans[0].interval;
				return new this.Subscription({
						customer: this.user.customerId,
						items: [{
							plan
						}],
						default_payment_method: this.paymentMethods[0].id,
						coupon: interval == 'year' ? this.activeCoupon?.code : null
					})
					.save()
					.then(async subscription => {
						this.subscription = subscription;
						return this.subscription;
					});
			},
			changeSubscription(nickname) {
				this.loading = true;
				const plan = this.plans.filter(plan => plan.nickname === nickname)[0].id;
				let update_options = { plan };
				if (nickname === 'Monthly' || this.subscription.status == 'trialing') {
					const end = this.subscription.current_period_end;
					update_options.proration_date = update_options.trial_end = end;
				}
				return this.updateSubscription([this.subscription.id, update_options])
					.then(async subscription => {
						this.loading = false;
						this.$bvModal.hide('modal-approve-plan-change');
						this.makeToast('Pro subscription upated!');
						this.subscription = subscription;
						await this.saveSubscriptionToUser();
						return this.subscription;
					}).catch(() => {
						this.loading = false;
					});
			},
			continueSubscription(id) {
				this.loading = true;
				this.Subscription.get(id).then(subscription => {
					this.updateSubscription([subscription.id, {
						cancel_at_period_end: false
					}, {}]).then(subscription => {
						this.subscription = subscription;
						this.saveSubscriptionToUser();
						this.makeToast('You subscription has been restarted');
						this.loading = false;
					}).catch(() => {
						this.loading = false;
						this.makeToast('There was an error trying to restart your subscription');
					});
				});
			},
			cancelSubscription(id) {
				this.loading = true;
				this.Subscription.get(id).then(subscription => {
					const cancelOpts = {};
					cancelOpts.cancel_at_period_end = true;
					this.updateSubscription([subscription.id, cancelOpts, {}]).then(subscription => {
						this.subscription = subscription;
						const { cancelReason, cancelFeedback } = this;
						this.user.last_cancellation = { cancelReason, cancelFeedback };
						this.saveSubscriptionToUser();
						this.loading = false;
						this.$bvModal.hide('modal-cancel-confirmation');
					}).catch(() => {
						this.loading = false;
						this.makeToast('There was an error trying to cancel your subscription');
					});
				});
			},
			loadSubscription() {
				return this.Subscription.find({
					query: {
						customer: this.user.customerId
					}
				}).then(res => {
					this.subscription = res.data.length ? res.data[0] : false;
				});
			},
			resetSubscriptionToUser () {
				this.user.subscription = { status: false };
				return this.user.save();
			},
			saveSubscriptionToUser () {
				const {
					id,
					cancel_at,
					cancel_at_period_end,
					canceled_at,
					created,
					current_period_end,
					current_period_start,
					customer,
					plan: {
						nickname: planName,
						amount: planPrice
					},
					status
				} = this.subscription;
				this.user.subscription = {
					id,
					cancel_at,
					cancel_at_period_end,
					canceled_at,
					created,
					current_period_end,
					current_period_start,
					customer,
					plan: {
						nickname: planName,
						amount: planPrice
					},
					status
				};
				return this.user.save();
			},
			createSetupIntent() {
				this.clientSecret = false;
				return new this.SetupIntent({
					customer: this.user.customerId
				})
				.save()
				.then(setupIntent => {
					this.clientSecret = setupIntent.client_secret;
				})
			},
			async deleteAccount () {
				this.$bvModal.show('modal-delete-account-confirmation');
				if (this.isPro) {
					await this.cancelSubscription(this.subscription.id);
				}
				await this.user.remove();
				window.localStorage.removeItem('feathers-jwt');
				this.$router.push('/goodbye');
			}
		},
		computed: {
			activeCoupon () {
				return this.coupons[0];
			},
			couponsParams () {
				return { query: {} };
			},
			Invoice () {
				return this.$FeathersVuex.api.Invoice;
			},
			PaymentMethod() {
				return this.$FeathersVuex.api.PaymentMethod;
			},
			Plan() {
				return this.$FeathersVuex.api.Plan;
			},
			SetupIntent() {
				return this.$FeathersVuex.api.SetupIntent;
			},
			Subscription() {
				return this.$FeathersVuex.api.Subscription;
			},
			activePlans () {
				return this.plans.filter(plan => plan.active === true);
			},
			filteredPlans () {
				return this.plans.filter(plan => plan.nickname === this.nickname);
			},
			routeName () {
				return this.$route.name;
			}
		},
		async mounted() {
			this.includeScript('https://js.stripe.com/v3/', () => {
				this.createSetupIntent();
				this.loadPlans();
				this.loadPaymentMethods();
				this.loadSubscription();
			}, true);
		}
	}
</script>

<style lang="scss">
	.stripe-wrapper {
		border-color: var(--color-600) !important;
	}
	.ElementsApp, .ElementsApp .InputElement {
		color: white !important;
	}
</style>
